import { Controller } from "@hotwired/stimulus"
import { useTransition } from "stimulus-use"

export default class extends Controller {
  static targets = ['menu']

  connect () {
    useTransition(this, {
      element: this.menuTarget,
      enterActive: 'fade-enter-active',
      enterFrom: 'fade-enter-from',
      enterTo: 'fade-enter-to',
      leaveActive: 'fade-leave-active',
      leaveFrom: 'fade-leave-from',
      leaveTo: 'fade-leave-to',
      hiddenClass: 'hidden',
      // Does it start in opened state?
      transitioned: false,
    })
  }

  toggle () {
    this.toggleTransition()
  }

  hide (event) {
    if (!this.element.contains(event.target) &&
      !this.menuTarget.classList.contains("hidden")) {
        this.leave()
    }
  }
}