import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  //static targets = ["toggleButton"]

  connect() {
    //this.initializeDarkMode();
  }

  toggle() {
    const isCurrentlyDark = document.documentElement.classList.contains("dark");
    this.applyDarkMode(!isCurrentlyDark);
    this.setUserPreference(!isCurrentlyDark);
    console.log("called toggl");
  }

  applyDarkMode(isDark) {
    if (isDark) {
      console.log("applying dark")
      document.documentElement.classList.add("dark");
    } else {
      console.log("removing dark")
      document.documentElement.classList.remove("dark");
    }
  }

  getUserPreference() {
    return localStorage.getItem("dark-mode");
  }

  setUserPreference(isDark) {
    localStorage.setItem("dark-mode", isDark ? "true" : "false");
  }

  getTimeBasedPreference() {
    const currentHour = new Date().getHours();
    return currentHour >= 7 && currentHour < 19 ? false : true;
  }

  initializeDarkMode() {
    const userPreference = this.getUserPreference();
    if (userPreference !== null) {
      this.applyDarkMode(userPreference === "true");
    } else {
      this.applyDarkMode(this.getTimeBasedPreference());
    }
  }
}