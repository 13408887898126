// Import and register all your controllers from the importmap under controllers/*

// Eager load all controllers defined in the import map under controllers/**/*_controller
//import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
//eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)

import { Application } from "@hotwired/stimulus"
const application = Application.start()

// Stimulus components
import RevealController from "stimulus-reveal"
application.register("reveal", RevealController)

import HwComboboxController from "@josefarias/hotwire_combobox"
application.register("hw-combobox", HwComboboxController)

import DropdownController from "./dropdown_controller"
application.register("dropdown", DropdownController)

import DarkModeController from "./dark_mode_controller"
application.register("dark-mode", DarkModeController)

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }